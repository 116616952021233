<template>
    <div>
        <b-card>
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Template Manager</b></h5>
                    <div class="d-flex justify-content-end">
                        <router-link :to="{ name: 'Template', params: {id: 'temp'}}" style="color: inherit">
                            <fa-icon :icon="['fas', 'square-plus']" size="lg" title="New Template"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <v-client-table ref="dTable" style="width: 100%" :columns="[...columns, 'info']" :data="templates"
                            :options="{...tOptions, sortable: columns, filterable: columns}" :theme="theme"
                            class="dataTable">
                <div slot="name" slot-scope="props">
                    <router-link :to="{ name: 'Template', params: {id: props.row._id}}" style="color: inherit" title="Go To Template">
                        {{ props.row.name }}
                    </router-link>
                </div>
                <div slot="info" slot-scope="props">
                    <router-link :to="{ name: 'Template', params: {id: props.row._id}}">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; width: 29px; margin-top: -3px; margin-bottom: -10px;"
                                      size="sm" variant="dark">
                                <fa-icon :icon="['fas', 'info']" size="lg"/>
                            </b-button>
                        </div>
                    </router-link>
                </div>
                <div slot="h__info" style="float: right">
                    <span style="float: right; margin-bottom: 0">Go to template</span>
                </div>
            </v-client-table>
        </b-card>
    </div>
</template>

<script>
import Report from '@/services/report';
import {ClientTable} from 'vue-tables-2';
import Vue from "vue";
Vue.use(ClientTable)

export default {
    name: "Templates",
    data() {
        return {
            templates: [],
            columns: ['name','description'],
            tOptions: {
                orderBy: {column: 'name', ascending: true},
                headings: {
                    name: 'Template Name',
                    description: 'Description',
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            theme: 'bootstrap4',
        }
    },
    created() {
        Report.getTemplates().then(result => {
            this.templates = result.data;
        });
    },
}
</script>

<style scoped>
</style>